import { Grid, Paper, Typography, TextField, Stack, CircularProgress, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimePicker } from '@mui/lab';
import { AdminSessionFormProps, AdminActionsFormValues, KammtActionSendForm } from './Form.types';
import { useFormik } from 'formik';
import { object, array, number } from 'yup';
import { useShowError } from 'hooks/useShowError';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { isAfter, isBefore } from 'date-fns';
import SelectList from 'components/SelectList/SelectList';
import { makePutKammtActionRequest } from 'core/services/actions';
import { ACTIONS } from 'core/Query';

const validationSchema = object().shape({
  selectedUsers: array().of(
    object().shape({
      id: number(),
      balance: number().min(1).max(999999).required(),
    }),
  ),
});

const getStatus = (startDate: Date | null, endDate: Date | null) => {
  if (!startDate || !endDate) return 'BAD';
  const now = new Date();
  if (isBefore(endDate, now)) return 'END';
  if (isAfter(startDate, now)) return 'WAIT';
  return 'DURING';
};

const KammtActionsForm = (props: AdminSessionFormProps) => {
  const { initialValues, actionUser, userIsLoading, name } = props;
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const id = initialValues.id;
  const showError = useShowError();
  const mutationPut = useMutation(makePutKammtActionRequest(id as number), {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_ACTION_FORM.EDIT.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries(ACTIONS);
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_ACTION_FORM.EDIT.ERROR' }), { variant: 'error' });
    },
  });
  const handleSubmitSave = (form: AdminActionsFormValues) => {
    const submitForm: KammtActionSendForm = {
      users: form.selectedUsers
        .filter((single) => single.id && single.balance)
        .map((single) => {
          return {
            id: single.id as number,
            balance: single.balance as number,
          };
        }),
    };
    if (id) {
      mutationPut.mutate(submitForm);
      return;
    }
  };
  const formik = useFormik({ initialValues, onSubmit: handleSubmitSave, validationSchema });
  const selectedUserError = showError('selectedUsers', formik, 'ADMIN_ACTION_FORM.SELECTED_USERS.ERROR.REQUIRED');
  return (
    <Grid item xs={12}>
      <Paper component="form" onSubmit={formik.handleSubmit}>
        <Grid container padding={1}>
          <Grid display="flex" justifyContent="flex-end" item xs={12}>
            <Stack direction="row" spacing={1}>
              <IconButton disabled={mutationPut.isLoading} type="submit">
                {mutationPut.isLoading ? <CircularProgress size={20} color="secondary" /> : <SaveIcon />}
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} padding={1}>
            <Stack spacing={2}>
              <Typography variant="subtitle2" color="inherit">
                <FormattedMessage id="ADMIN_ACTION_FORM.SESSION_NUMBER" />{' '}
                {name || <FormattedMessage id="ADMIN_SESSION_FORM.NOT_SAVE" />}
              </Typography>
              <Typography variant="body2" color="inherit" sx={{ paddingBottom: 2 }}>
                <FormattedMessage id="ADMIN_SESSION_FORM.STATUS" />{' '}
                <FormattedMessage
                  id={`ADMIN_SESSION_FORM.STATUS.${getStatus(formik.values.startDate, formik.values.endDate)}`}
                />
              </Typography>
              <DateTimePicker
                value={formik.values.startDate}
                disabled
                inputFormat="yyyy-MM-dd HH:mm"
                maxDate={formik.values.endDate}
                mask="____-__-__ __:__"
                onChange={(value) => {
                  formik.setFieldValue('startDate', value);
                }}
                label={<FormattedMessage id="ADMIN_SESSION_FORM.START_DATE" />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name="startDate"
                    error={Boolean(showError('startDate', formik))}
                    helperText={showError('startDate', formik)}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <DateTimePicker
                value={formik.values.endDate}
                inputFormat="yyyy-MM-dd HH:mm"
                mask="____-__-__ __:__"
                disabled
                minDate={formik.values.startDate}
                onChange={(value) => {
                  formik.setFieldValue('endDate', value);
                }}
                label={<FormattedMessage id="ADMIN_SESSION_FORM.END_DATE" />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="endDate"
                    error={Boolean(showError('endDate', formik))}
                    helperText={showError('endDate', formik)}
                    fullWidth
                    onBlur={formik.handleBlur}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} padding={1}>
            <TextField
              name="description"
              value={formik.values.description}
              disabled
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(showError('description', formik))}
              helperText={showError('description', formik)}
              fullWidth
              multiline
              minRows={8}
              maxRows={8}
              label="Opis"
            />
          </Grid>
          <Grid item xs={12} md={4} padding={1}>
            <SelectList
              type="fied"
              title={<FormattedMessage id="ACTION.FORM.ASIGN.USERS" />}
              error={selectedUserError}
              optionsIsLoading={Boolean(userIsLoading)}
              disabled={mutationPut.isLoading}
              options={actionUser.map((single) => ({ ...single, balance: '' })) || []}
              selectedOptions={formik.values.selectedUsers}
              fieldName="selectedUsers"
              innerFieldName="balance"
              errors={formik.errors.selectedUsers}
              inputSetValue={formik.setFieldValue}
              inputHandleBlur={formik.handleBlur}
              onChange={(values) => formik.setFieldValue('selectedUsers', values)}
              emptyMessage={<FormattedMessage id="ADMIN_SESSION_FORM.NO_ADD_USERS" />}
              allMessage={<FormattedMessage id="ADMIN_SESSION_FORM.ALL_USERS" />}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default KammtActionsForm;
