import useGlobalErrors from 'hooks/useGlobalErrors/useGlobalErrors';
import React from 'react';

const AsignOrderEmpty = () => {
  const { globalFormatErrors, setErrors } = useGlobalErrors();
  React.useEffect(() => {
    setErrors(['KAM_MT_SESSION.EMPTY']);
  }, [setErrors]);
  return globalFormatErrors;
};

export default AsignOrderEmpty;
