import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Box, Button, Dialog, DialogActions, DialogTitle, Grid, Pagination, Paper } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import PageHeaderNoSort from 'components/PageHeaderNoSort/PageHeader';
import KamMtVoucherForm from 'components/KamMtVoucher/Form';
import { VOUCHERS } from 'core/Query';
import { makeDeleteVoucherRequest, makeGetVouchersRequest } from 'core/services/voucher';
import { Paginate } from 'types/Paginate';
import { Voucher } from 'types/Voucher';
import useCacheQuery from 'hooks/useCacheQuery';

const Vouchers = () => {
  const [addVoucher, setAddVoucher] = React.useState<boolean>(false);
  const [deleteId, setDeleteId] = React.useState<number | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const [page, setPage] = React.useState<number>(1);
  const dataQuery = useQuery([VOUCHERS, page], () => makeGetVouchersRequest(page), {
    select(response) {
      return response.data;
    },
    onSuccess(data: Paginate<Voucher>) {
      if (data.data.length === 0 && page > 1) {
        setPage((old) => old - 1);
      }
    },
    refetchOnWindowFocus: false,
  });
  const { data, pages } = useCacheQuery({ data: dataQuery.data?.data, total: dataQuery.data?.total });

  const mutatuinDelete = useMutation(makeDeleteVoucherRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'VOUCHERS.DELETE.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries([VOUCHERS]);
      setDeleteId(null);
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'VOUCHERS.DELETE.ERROR' }), { variant: 'error' });
    },
  });

  const confirmDelete = () => {
    if (!deleteId) return;
    mutatuinDelete.mutate(deleteId);
  };

  const handleDelete = (id: number | undefined) => {
    if (!id) return setAddVoucher(false);
    return setDeleteId(id);
  };
  return (
    <Grid container spacing={2}>
      <PageHeaderNoSort>
        <FormattedMessage id="KAM_MT.VOUCHERS.TITLE" />
      </PageHeaderNoSort>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Button color="secondary" disabled={addVoucher} variant="contained" onClick={() => setAddVoucher(true)}>
            <FormattedMessage id="KAM_MT.VOUCHERS.ADD_BUTTON" />
          </Button>
        </Box>
      </Grid>
      {addVoucher && (
        <KamMtVoucherForm
          status="pending"
          initialValues={{
            id: undefined,
            name: '',
            type: '',
            company: '',
            amount: '',
            promotionName: '',
            phone: '',
            street: '',
            houseNumber: '',
            flatNumber: null,
            zipCode: '',
            city: '',
          }}
          deleteIsLoading={mutatuinDelete.isLoading}
          handleDelete={handleDelete}
          closeAdd={() => setAddVoucher(false)}
        />
      )}
      {data?.map((single) => (
        <KamMtVoucherForm
          key={single.id}
          id={single.id}
          initialValues={single}
          status={single.status}
          deleteIsLoading={mutatuinDelete.isLoading}
          edit
          handleDelete={handleDelete}
        />
      ))}
      {dataQuery.isFetched && data?.length === 0 ? (
        <Grid item xs={12}>
          <Paper>
            <Box display="flex" justifyContent="center" padding={2}>
              <FormattedMessage id="KAMMT.VOUCHERS.EMPTY" />
            </Box>
          </Paper>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Paper>
          <Box display="flex" justifyContent="center" padding={2}>
            <Pagination
              page={page}
              onChange={(_, page) => setPage(page)}
              count={pages}
              variant="outlined"
              color="secondary"
            />
          </Box>
        </Paper>
      </Grid>
      <Dialog
        open={Boolean(deleteId)}
        onClose={() => {
          if (mutatuinDelete.isLoading) return;
          setDeleteId(null);
        }}
      >
        <DialogTitle>
          <FormattedMessage id="VOUCHER.CONFIRM.DELETE" />
        </DialogTitle>
        <DialogActions>
          <Button disabled={mutatuinDelete.isLoading} onClick={() => setDeleteId(null)}>
            <FormattedMessage id="CONFIRM.NO" />
          </Button>
          <Button color="secondary" disabled={mutatuinDelete.isLoading} variant="contained" onClick={confirmDelete}>
            <FormattedMessage id="CONFIRM.YES" />
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Vouchers;
