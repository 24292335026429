import { Menu } from './menu';

const panelUserMenuItems = (actions: { id: number; name: string }[]): Menu[] => {
  const submenu =
    actions.length > 0
      ? actions.map((single) => ({
          title: 'PANEL_USERS_MENU.ACTION',
          titleValues: { action: single.name },
          path: `/panel/action/${single.id}`,
        }))
      : [{ title: 'PANEL_USERS_MENU.ACTION.EMPTY', path: `/panel/action/empty`, disabled: true }];
  return [
    {
      title: 'PANEL_USERS_MENU.BANERS',
      path: '/panel',
    },
    {
      title: 'PANEL_USERS_MENU.ACTIONS',
      path: '/panel',
      submenu,
    },
    {
      title: 'PANEL_USERS_MENU.ORDERS',
      path: '/panel/orders',
    },
    {
      title: 'PANEL_USERS_MENU.ARCHIVES',
      path: '/panel/orders-archives',
    },
    {
      title: 'PANEL_USERS_MENU.CONTACT',
      path: '/panel/contact',
    },
  ];
};
export default panelUserMenuItems;
