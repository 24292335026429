import axios from 'core/clients/api';
import { Message } from 'types/Message';
import { Paginate } from 'types/Paginate';

const api = process.env.REACT_APP_API_URL;

export const makeGetMessagesRequest = (page: number, sortValue: string) =>
  axios.get<Paginate<Message>>(`${api}/messages?perPage=10&page=${page}&orderBy=${sortValue}&order=desc`);

export const makePostMessagesRequest = (message: string) => axios.post<void>(`${api}/messages`, { message });

export const makePutMessageChangeStatusRequest = ({ id, status }: { id: number; status: string }) =>
  axios.put<void>(`${api}/messages/${id}`, { status });
