import axios from 'axios';
import { ChangePasswordForm } from 'views/oauth/ChangePassword/ChangePassword.type';
import { ForgotPasswordForm } from 'views/oauth/ForgetPassword/ForgetPassword.type';
import { LoginForm } from 'views/oauth/Login/Login.type';

const api = process.env.REACT_APP_API_URL;

export const makePostLoginRequest = (form: LoginForm) =>
  axios.post<{ token: string }>(`${api}/login`, {
    email: form.login,
    password: form.password,
  });

export const makePostForgotRequest = (form: ForgotPasswordForm) =>
  axios.post<void>(`${api}/forgot-password`, {
    email: form.login,
  });

export const makePostChangePasswordRequest = (token: string, email: string) => (form: ChangePasswordForm) =>
  axios.post<void>(`${api}/change-password/${token}/${email}`, {
    password: form.password,
    password_confirmation: form.passwordConfirmation,
  });
