import { FormikErrors, FormikTouched } from 'formik';
import isArray from 'lodash/isArray';
import { useIntl } from 'react-intl';

interface Validate<T> {
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
}

export const useShowError = () => {
  const intl = useIntl();
  return function <T>(field: keyof T, validate: Validate<T>, arrayError?: string): string | null {
    let fieldError = validate.errors[field];
    if (isArray(fieldError) && arrayError) {
      fieldError = arrayError as any;
    }
    return validate.touched[field] && fieldError ? intl.formatMessage({ id: fieldError as string }) : null;
  };
};
