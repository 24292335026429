import { useIntl } from 'react-intl';

const useFormatErrors = () => {
  const intl = useIntl();
  const formatError = (error: string | undefined) => {
    if (!error) return '';
    const splitError = error.split(', ');
    if (splitError.length > 1) {
      return splitError.map((error) => intl.formatMessage({ id: error })).join(', ');
    }
    return intl.formatMessage({ id: error });
  };
  return { formatError };
};

export default useFormatErrors;
