import PanelLayout from 'components/PanelLayout/PanelLayout';
import { Route, Routes, Navigate } from 'react-router-dom';
import KamMtSession from './AsignOrder/AsignOrder';
import orderPanelKamMtMenuItems from 'core/menu/orderPanelKamMtMenuItems';
import { useQuery } from 'react-query';
import { makeGetSessionAllActiveRequest } from 'core/services/sessions';
import LinearProgress from 'components/LinearProgress/LinearProgress';
import KamMtSessionEmpty from './AsignOrderEmpty/AsignOrderEmpty';
import Orders from './Orders/Orders';
import Users from '../shared/Users/Users';
import SendMessage from '../../shared/SendMessage/SendMessage';
import OrderArchives from '../../shared/sessionPanel/OrderArchives/OrderArchives';
import Vouchers from './Vouchers/Vouchers';
import { FormattedMessage } from 'react-intl';
import { KAMMT_ACTIVE_SESSION } from 'core/Query';

const ChooseSession = ({ activeSession }: { activeSession: { id: number; name: string }[] }) => {
  if (activeSession.length === 0) return <Navigate to="/panel/sessions/asign-order/empty" />;
  return <Navigate to={`/panel/sessions/asign-order/${activeSession[0].id}`} />;
};

const SessionPanel = () => {
  const dataQuery = useQuery([KAMMT_ACTIVE_SESSION], makeGetSessionAllActiveRequest);
  const activeSession = dataQuery.data?.data.map((single) => ({ name: single.name, id: single.id })) || [];
  if (dataQuery.isLoading) return <LinearProgress />;
  return (
    <>
      <Routes>
        <Route
          element={
            <PanelLayout
              disabledMenu={false}
              linkPath="/"
              menu={orderPanelKamMtMenuItems(activeSession)}
              title={<FormattedMessage id="APP.ORDERS_PANEL" />}
            />
          }
        >
          <Route path="/asign-order/empty" element={<KamMtSessionEmpty />} />
          <Route path="/asign-order/:id" element={<KamMtSession />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/distributors" element={<Users />} />
          <Route path="/orders-archives" element={<OrderArchives />} />
          <Route path="/message" element={<SendMessage />} />
          <Route path="/vouchers" element={<Vouchers />} />
        </Route>
        <Route path="/" element={<ChooseSession activeSession={activeSession} />} />
      </Routes>
    </>
  );
};

export default SessionPanel;
