import axios from 'core/clients/api';
import { OrderListArchive } from 'types/Order';
import { Paginate } from 'types/Paginate';

const api = process.env.REACT_APP_API_URL;

export const makeGetActionOrderArchiveRequest = (page: number, sortValue: string) =>
  axios.get<Paginate<OrderListArchive>>(
    `${api}/action-orders/archives?perPage=10&page=${page}&orderBy=${sortValue}&order=desc`,
  );
