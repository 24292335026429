import React from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Button,
  Stack,
  ListItem,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { makePostLogoutRequest } from 'core/services/user';
import { useSnackbar } from 'notistack';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import Logo from 'images/logo.png';
import { PanelLayoutProps } from './PanelLayout.type';
import Drawer from 'components/Drawer/Drawer';
import { useUser } from 'context/User/User';
import { UserRole } from 'types/Users';

const PanelLayout = (props: PanelLayoutProps) => {
  const queryClient = useQueryClient();
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);
  const user = useUser();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const useOpenMenu = openMenu || isDesktop;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const logoutMutation = useMutation(makePostLogoutRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'APP_BAR.LOGOUT.SUCCESS' }), { variant: 'success' });
      queryClient.clear();
      navigate('/login');
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'APP_BAR.LOGOUT.ERROR' }), { variant: 'error' });
    },
  });
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const role = React.useCallback(
    (role: UserRole) => {
      switch (role) {
        case UserRole.AMIN:
          return intl.formatMessage({ id: 'APP_BAR.ROLE.ADMIN' });
        case UserRole.KAM_MT:
          return intl.formatMessage({ id: 'APP_BAR.ROLE.KAM_MT' });
        default:
          return intl.formatMessage({ id: 'APP_BAR.ROLE.USER' });
      }
    },
    [intl],
  );
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar color="secondary" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {!isDesktop && (
            <IconButton color="inherit" onClick={() => setOpenMenu(!openMenu)}>
              <MenuIcon />
            </IconButton>
          )}
          <Box display="flex" alignItems="center" sx={{ flex: 1 }}>
            <Link to={props.linkPath}>
              <img src={Logo} width={200} alt="Logo" />
            </Link>
            {props.title && (
              <Typography
                variant="h6"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    display: 'none',
                  },
                }}
              >
                {props.title}
              </Typography>
            )}
          </Box>
          <Button
            ref={buttonRef}
            color="inherit"
            sx={{ textTransform: 'none', textAlign: 'left' }}
            onClick={() => setOpen(true)}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <AccountCircle />
              {isDesktop && (
                <Box>
                  <Typography variant="subtitle2">{user.name}</Typography>
                  <Typography variant="body2">{role(user.role.id)}</Typography>
                </Box>
              )}
            </Stack>
          </Button>
          <Menu open={open} anchorEl={buttonRef.current} onClose={() => setOpen(false)}>
            {!isDesktop && (
              <ListItem>
                <Box display="flex" sx={{ flexDirection: 'column' }}>
                  <Typography variant="subtitle2">{user.name}</Typography>
                  <Typography variant="body2">{role(user.role.id)}</Typography>
                </Box>
              </ListItem>
            )}
            <MenuItem onClick={() => logoutMutation.mutate()}>
              <FormattedMessage id="APP_BAR.MENU.LOGOUT" />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      {!props.disabledMenu && (
        <Drawer isDesktop={isDesktop} open={useOpenMenu} menu={props.menu} onClose={() => setOpenMenu(false)} />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          paddingX: 2,
          maxWidth: '100%',
          minHeight: '100vh',
          paddingBottom: 4,
        }}
      >
        <Toolbar />
        <Container sx={{ marginTop: 2 }}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default PanelLayout;
