import { FormattedMessage } from 'react-intl';
import { OrderStatus } from 'types/Order';

const formatStatus = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.CREATED:
      return <FormattedMessage id="STATUS.CREATED" />;
    case OrderStatus.DURING:
      return <FormattedMessage id="STATUS.DURING" />;
    case OrderStatus.FINISH:
      return <FormattedMessage id="STATUS.FINISH" />;
  }
};

export default formatStatus;
