import React from 'react';
import { Grid, Paper, Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import PageHeaderNoSort from 'components/PageHeaderNoSort/PageHeader';
import { makeDeleteBanerRequest, makeGetBanersRequest } from 'core/services/baners';
import AdminBanerForm from 'components/AdminBaner/Form';
import { ADMIN_BANERS } from 'core/Query';

const AdminBaners = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const [addSession, setAddSession] = React.useState<boolean>(false);
  const [deleteId, setDeleteId] = React.useState<number | null>(null);
  const banersQuery = useQuery(ADMIN_BANERS, makeGetBanersRequest, {
    select(response) {
      return response.data;
    },
  });
  const mutatuinDelete = useMutation(makeDeleteBanerRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_BANER_FORM.DELETE.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries(ADMIN_BANERS);
      setDeleteId(null);
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_BANER_FORM.DELETE.ERROR' }), { variant: 'error' });
    },
  });
  const confirmDelete = () => {
    if (!deleteId) return;
    mutatuinDelete.mutate(deleteId);
  };
  const handleDelete = (id: number | undefined) => {
    if (!id) return setAddSession(false);
    setDeleteId(id);
  };
  return (
    <Grid container spacing={2}>
      {(banersQuery.isLoading || banersQuery.isRefetching) && <FullPageLoader />}
      <PageHeaderNoSort>
        <FormattedMessage id="ADMIN_BANERS.TITLE" />
      </PageHeaderNoSort>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Button color="secondary" disabled={addSession} variant="contained" onClick={() => setAddSession(true)}>
            <FormattedMessage id="ADMIN_BANERS.ADD_BUTTON" />
          </Button>
        </Box>
      </Grid>
      {addSession && (
        <AdminBanerForm
          deleteLoading={mutatuinDelete.isLoading}
          initialValues={{
            id: undefined,
            image: null,
            url: '',
          }}
          handleDelete={handleDelete}
          closeAdd={() => setAddSession(false)}
        />
      )}
      {banersQuery.data?.map((single) => (
        <AdminBanerForm
          key={single.id}
          deleteLoading={mutatuinDelete.isLoading}
          handleDelete={handleDelete}
          editMode
          initialValues={{
            id: single.id,
            url: single.url,
            image: single.fullPath,
          }}
        />
      ))}
      {banersQuery.isFetched && banersQuery.data?.length === 0 ? (
        <Grid item xs={12}>
          <Paper>
            <Box display="flex" justifyContent="center" padding={2}>
              <FormattedMessage id="ADMIN.ACTIONS.BANERS.EMPTY" />
            </Box>
          </Paper>
        </Grid>
      ) : null}
      <Dialog
        open={Boolean(deleteId)}
        onClose={() => {
          if (mutatuinDelete.isLoading) return;
          setDeleteId(null);
        }}
      >
        <DialogTitle>
          <FormattedMessage id="ADMIN_BANERS.CONFIRM.DELETE" />
        </DialogTitle>
        <DialogActions>
          <Button disabled={mutatuinDelete.isLoading} onClick={() => setDeleteId(null)}>
            <FormattedMessage id="CONFIRM.NO" />
          </Button>
          <Button color="secondary" disabled={mutatuinDelete.isLoading} variant="contained" onClick={confirmDelete}>
            <FormattedMessage id="CONFIRM.YES" />
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AdminBaners;
