import React from 'react';
import { Grid, MenuItem, Paper, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { PageHeaderProps } from './PageHeader.type';

const PageHeader: React.FunctionComponent<PageHeaderProps> = (props) => {
  const { children, sortValue, onChangeValue, sortItems } = props;
  return (
    <Grid item xs={12}>
      <Paper>
        <Box display="flex" sx={(theme) => ({ [theme.breakpoints.down('md')]: { flexDirection: 'column' } })}>
          <Box display="flex" alignItems="center" padding={1} flex={1}>
            <Typography variant="h6">{children}</Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            padding={2}
            sx={(theme) => ({
              backgroundColor: 'secondary.main',
              color: 'secondary.contrastText',
              [theme.breakpoints.down('md')]: { flexDirection: 'column' },
            })}
          >
            <Typography variant="subtitle2" sx={{ marginRight: 1 }}>
              <FormattedMessage id="PAGE_HEADER.SORT" />
            </Typography>
            <Select
              value={sortValue}
              variant="outlined"
              onChange={(_, element: any) => onChangeValue(element.props.value)}
              sx={{
                minWidth: 200,
                '.MuiOutlinedInput-input': { backgroundColor: '#fff', padding: '4px' },
              }}
            >
              {sortItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default PageHeader;
