import React from 'react';
import { IntlProvider } from 'react-intl';
import { Lang } from 'types/Lang';
import messagesPL from 'lang/pl_PL.json';
import messageEn from 'lang/en.json';
import messageCz from 'lang/cz.json';
import messageHun from 'lang/hun.json';
import messageRon from 'lang/ron.json';
import messageSk from 'lang/sk.json';

interface ILocaleProvider {
  locale: Lang;
  changeLocale: (newLocale: Lang) => void;
}

const avilableLocale: Record<Lang, any> = {
  pl: messagesPL,
  en: messageEn,
  cz: messageCz,
  hun: messageHun,
  ron: messageRon,
  sk: messageSk,
};

const LocaleContext = React.createContext<ILocaleProvider | undefined>(undefined);

export const useLocale = () => {
  const locale = React.useContext(LocaleContext);
  if (!locale) throw new Error('use hook locale inside provider locale');

  return locale;
};

const LocaleProvider: React.FunctionComponent = ({ children }) => {
  const [locale, changeLocale] = React.useState<Lang>('pl');
  return (
    <LocaleContext.Provider value={{ locale, changeLocale }}>
      <IntlProvider locale={locale} defaultLocale="pl" messages={avilableLocale[locale]}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
