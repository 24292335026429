import React from 'react';
import {
  Grid,
  Paper,
  TextField,
  Stack,
  CircularProgress,
  IconButton,
  FormHelperText,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Button,
  Checkbox,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserForm, UserFormProps } from './Form.types';
import { useFormik } from 'formik';
import { object, string, number } from 'yup';
import { useShowError } from 'hooks/useShowError';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { UserRole } from 'types/Users';
import { makePostUserRequest, makePutUserRequest } from 'core/services/user';
import { USERS, USERS_FILTERS_OPTIONS, USERS_ONLY_KAMMT } from 'core/Query';

const validationSchema = object().shape({
  name: string().required('USER_FORM.NAME.ERROR.REQUIRED').max(255, 'USER_FORM.NAME.ERROR.MAX'),
  email: string()
    .required('USER_FORM.EMAIL.ERROR.REQUIRED')
    .email('USER_FORM.EMAIL.ERROR.TYPE')
    .max(255, 'USER_FORM.EMAIL.ERROR.MAX'),
  roleId: number().typeError('USER_FORM.ROLE_ID.ERROR.REQUIRED').required('USER_FORM.ROLE_ID.ERROR.REQUIRED'),
  phone: string().required('USER_FORM.PHONE.ERROR.REQUIRED').max(255, 'USER_FORM.PHONE.ERROR.MAX'),
  street: string().required('USER_FORM.STREET.ERROR.REQUIRED').max(255, 'USER_FORM.STREET.ERROR.MAX'),
  houseNumber: string().required('USER_FORM.HOUSE_NUMBER.ERROR.REQUIRED').max(20, 'USER_FORM.HOUSE_NUMBER.ERROR.MAX'),
  flatNumber: string().nullable().max(20, 'USER_FORM.FLAT_NUMBER.ERROR.MAX'),
  zipCode: string().required('USER_FORM.ZIP_CODE.ERROR.REQUIRED').max(20, 'USER_FORM.ZIP_CODE.ERROR.MAX'),
  city: string().required('USER_FORM.CITY.ERROR.REQUIRED').max(255, 'USER_FORM.CITY.ERROR.MAX'),
  company: string().required('USER_FORM.COMPANY.ERROR.REQUIRED').max(255, 'USER_FORM.COMPANY.ERROR.MAX'),
  userId: number()
    .nullable()
    .when('roleId', {
      is: (value: number) => value === UserRole.DISTRIBUTOR,
      then: (schema) => schema.required('USER_FORM.USER_ID.ERROR.REQUIRED'),
    }),
  lang: string()
    .required('USER_FORM.LANG.ERROR.REQUIRED')
    .is(['pl', 'en', 'cz', 'hun', 'ron', 'sk'], 'USER_FORM.LANG.ERROR.REQUIRED'),
});

const UsersForm = (props: UserFormProps) => {
  const { initialValues, users, userIsLoading, closeAdd, handleDelete, deleteIsLoading, edit } = props;
  const [open, setOpen] = React.useState(!edit);
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const id = initialValues.id;
  const showError = useShowError();
  const mutationPost = useMutation(makePostUserRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'USER.ADD.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries([USERS]);
      queryClient.refetchQueries(USERS_ONLY_KAMMT);
      queryClient.refetchQueries(USERS_FILTERS_OPTIONS);
      if (closeAdd) closeAdd();
    },
    onError(error: any) {
      if (error.isAxiosError) {
        if (error.response.status === 422) {
          if (error.response.data.errors?.email) {
            enqueueSnackbar(intl.formatMessage({ id: 'USER.ADD.ERROR.EXIST' }), { variant: 'error' });
            return;
          }
        }
      }
      enqueueSnackbar(intl.formatMessage({ id: 'USER.ADD.ERROR' }), { variant: 'error' });
    },
  });
  const mutationPut = useMutation(makePutUserRequest(id as number), {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'USER.EDIT.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries([USERS]);
      queryClient.refetchQueries(USERS_ONLY_KAMMT);
      queryClient.refetchQueries(USERS_FILTERS_OPTIONS);
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'USER.EDIT.ERROR' }), { variant: 'error' });
    },
  });
  const handleSubmitSave = (form: UserForm) => {
    if (id) {
      mutationPut.mutate(form as any);
      return;
    }
    mutationPost.mutate(form);
  };

  const formik = useFormik({ initialValues, onSubmit: handleSubmitSave, validationSchema });
  const selectedRoleError = showError('roleId', formik);
  const isLoading = mutationPost.isLoading || mutationPut.isLoading || deleteIsLoading;
  const saveLoading = mutationPost.isLoading || mutationPut.isLoading;
  const selectedUserParent = React.useMemo(() => {
    if (!users) return null;

    return users.find((user) => user.id === formik.values.userId) || null;
  }, [formik.values.userId, users]);
  return (
    <Grid item xs={12}>
      <Paper component="form" onSubmit={formik.handleSubmit}>
        <Grid container padding={1}>
          <Grid display="flex" justifyContent="flex-end" item xs={12}>
            <Stack direction="row" spacing={1} alignItems="center">
              {!edit && (
                <Typography variant="subtitle2" color="error">
                  <FormattedMessage id="COMMON.NOT_SAVE" />
                </Typography>
              )}
              <IconButton disabled={isLoading} onClick={() => handleDelete(id)}>
                {deleteIsLoading ? <CircularProgress size={20} color="secondary" /> : <DeleteIcon />}
              </IconButton>
              <IconButton disabled={isLoading} type="submit">
                {saveLoading ? <CircularProgress size={20} color="secondary" /> : <SaveIcon />}
              </IconButton>
            </Stack>
          </Grid>
          <Grid container padding={1}>
            <Grid item xs={12} md={3} padding={1}>
              <TextField
                name="company"
                value={formik.values.company}
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('company', formik))}
                helperText={showError('company', formik)}
                fullWidth
                label={<FormattedMessage id="USERS.FORM.COMPANY" />}
              />
            </Grid>
            <Grid item xs={12} md={3} padding={1}>
              <TextField
                name="name"
                value={formik.values.name}
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('name', formik))}
                helperText={showError('name', formik)}
                fullWidth
                label={<FormattedMessage id="USERS.FORM.NAME" />}
              />
            </Grid>
            <Grid item xs={12} md={3} padding={1}>
              <TextField
                name="city"
                value={formik.values.city}
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('city', formik))}
                helperText={showError('city', formik)}
                fullWidth
                label={<FormattedMessage id="USERS.FORM.CITY" />}
              />
            </Grid>
            <Grid item xs={12} md={3} padding={1}>
              <Button
                sx={{ height: 56 }}
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => setOpen(!open)}
              >
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={open}>
              <Grid container padding={1}>
                <Grid item xs={12} md={3} padding={1}>
                  <Stack spacing={2}>
                    <TextField
                      name="street"
                      value={formik.values.street}
                      disabled={isLoading}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(showError('street', formik))}
                      helperText={showError('street', formik)}
                      fullWidth
                      label={<FormattedMessage id="USERS.FORM.STREET" />}
                    />
                    <TextField
                      name="zipCode"
                      value={formik.values.zipCode}
                      disabled={isLoading}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(showError('zipCode', formik))}
                      helperText={showError('zipCode', formik)}
                      fullWidth
                      label={<FormattedMessage id="USERS.FORM.ZIP_CODE" />}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3} padding={1}>
                  <Stack spacing={2}>
                    <TextField
                      name="phone"
                      value={formik.values.phone}
                      disabled={isLoading}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(showError('phone', formik))}
                      helperText={showError('phone', formik)}
                      fullWidth
                      label={<FormattedMessage id="USERS.FORM.PHONE" />}
                    />
                    <TextField
                      name="houseNumber"
                      value={formik.values.houseNumber}
                      disabled={isLoading}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(showError('houseNumber', formik))}
                      helperText={showError('houseNumber', formik)}
                      fullWidth
                      label={<FormattedMessage id="USERS.FORM.HOUSE_NUMBER" />}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3} padding={1}>
                  <Stack spacing={2}>
                    <TextField
                      name="email"
                      value={formik.values.email}
                      disabled={isLoading || edit}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(showError('email', formik))}
                      helperText={showError('email', formik)}
                      fullWidth
                      label={<FormattedMessage id="USERS.FORM.EMAIL" />}
                    />
                    <TextField
                      name="flatNumber"
                      value={formik.values.flatNumber}
                      disabled={isLoading}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(showError('flatNumber', formik))}
                      helperText={showError('flatNumber', formik)}
                      fullWidth
                      label={<FormattedMessage id="USERS.FORM.FLAT_NUMBER" />}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3} padding={1}>
                  <Stack spacing={2}>
                    <FormControl>
                      <RadioGroup
                        name="roleId"
                        value={formik.values.roleId}
                        onChange={(event) => {
                          formik.setFieldValue('userId', '');
                          formik.setFieldValue('roleId', parseInt(event.target.value));
                        }}
                        onBlur={formik.handleBlur}
                      >
                        <FormControlLabel
                          disabled={isLoading}
                          value={2}
                          control={<Radio size="small" />}
                          label={<FormattedMessage id="USERS.FORM.KAM_MT" />}
                        />
                        <FormControlLabel
                          value={3}
                          disabled={isLoading}
                          control={<Radio size="small" />}
                          label={<FormattedMessage id="USERS.FORM.DISTRIBUTOR" />}
                        />
                        {Boolean(selectedRoleError) && <FormHelperText error>{selectedRoleError}</FormHelperText>}
                      </RadioGroup>
                    </FormControl>
                    <Autocomplete
                      disablePortal
                      value={selectedUserParent}
                      options={users}
                      loading={userIsLoading}
                      disabled={isLoading || formik.values.roleId !== UserRole.DISTRIBUTOR}
                      openOnFocus
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      loadingText="Trwa ładowanie"
                      noOptionsText="Brak KAM MT"
                      getOptionLabel={(option) => option.name}
                      onChange={(_, value) => {
                        formik.setFieldValue('userId', value ? value.id : null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="userId"
                          label={<FormattedMessage id="USER.USERS" />}
                          error={Boolean(showError('userId', formik))}
                          helperText={showError('userId', formik)}
                          onBlur={formik.handleBlur}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3} padding={1}>
                  <FormControl fullWidth error={Boolean(showError('lang', formik))}>
                    <InputLabel id="user-lang">
                      <FormattedMessage id="USER.LANG" />
                    </InputLabel>
                    <Select
                      name="lang"
                      labelId="user-lang"
                      disabled={isLoading}
                      value={formik.values.lang}
                      label={<FormattedMessage id="USER.LANG" />}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="pl">
                        <FormattedMessage id="LANG.PL" />
                      </MenuItem>
                      <MenuItem value="en">
                        <FormattedMessage id="LANG.EN" />
                      </MenuItem>
                      <MenuItem value="cz">
                        <FormattedMessage id="LANG.CZ" />
                      </MenuItem>
                      <MenuItem value="hun">
                        <FormattedMessage id="LANG.HUN" />
                      </MenuItem>
                      <MenuItem value="ron">
                        <FormattedMessage id="LANG.RON" />
                      </MenuItem>
                      <MenuItem value="sk">
                        <FormattedMessage id="LANG.SK" />
                      </MenuItem>
                    </Select>
                    {Boolean(showError('lang', formik)) && <FormHelperText>{showError('lang', formik)}</FormHelperText>}
                  </FormControl>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="is_allowed_multiple_orders"
                          value={formik.values.isAllowedMultipleOrders}
                          checked={formik.values.isAllowedMultipleOrders}
                          onChange={(_, value) => {
                            formik.setFieldValue('isAllowedMultipleOrders', value);
                          }}
                        />
                      }
                      label={<FormattedMessage id="USER.ALLOW_FOR_MANY_ORDERS" />}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default UsersForm;
