import axios from 'core/clients/api';
import { Baner } from 'types/Baner';

const api = process.env.REACT_APP_API_URL;

export const makeGetBanersRequest = () => axios.get<Baner[]>(`${api}/baners`);

export const makePostBanerRequest = (form: FormData) => axios.post<void>(`${api}/baners`, form);

export const makeDeleteBanerRequest = (id: number) => axios.delete<void>(`${api}/baners/${id}`);
