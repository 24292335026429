import React from 'react';
import { Alert, Stack, Grid } from '@mui/material';
import useFormatErrors from 'hooks/useFormatErrors/useFormatErrors';
import { Box } from '@mui/system';

const useGlobalErrors = () => {
  const { formatError } = useFormatErrors();
  const [errors, setErrors] = React.useState<string[]>([]);
  const globalFormatErrors = React.useMemo(() => {
    return (
      <Grid container>
        <Grid item xs={12} paddingX={2}>
          <Stack spacing={2}>
            {errors.map((error) => (
              <Box key={error} paddingY={2}>
                <Alert severity="error">{formatError(error)}</Alert>
              </Box>
            ))}
          </Stack>
        </Grid>
      </Grid>
    );
  }, [errors, formatError]);

  return {
    hasError: errors.length > 0,
    globalFormatErrors,
    setErrors,
  };
};

export default useGlobalErrors;
