import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const FullPageLoader = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={(theme) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(255,255,255,0.3)',
        zIndex: 999,
        [theme.breakpoints.up('sm')]: {
          paddingLeft: '270px',
        },
      })}
    >
      <CircularProgress color="secondary" />
    </Box>
  );
};

export default FullPageLoader;
