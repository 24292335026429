import { FormattedMessage } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import PanelLayout from 'components/PanelLayout/PanelLayout';
import actionPanelKammtMenuItems from 'core/menu/actionPanelKammtMenuItems';
import OrderArchives from '../../shared/actionPanel/OrderArchives/OrderArchives';
import Orders from '../../shared/actionPanel/Orders/Orders';
import KammtActions from './Actions/Actions';
import Users from '../shared/Users/Users';

const ActionPanel = () => {
  return (
    <Routes>
      <Route
        element={
          <PanelLayout
            linkPath="/"
            disabledMenu={false}
            menu={actionPanelKammtMenuItems}
            title={<FormattedMessage id="APP.ACTIONS_PANEL" />}
          />
        }
      >
        <Route path="/" element={<KammtActions />} />
        <Route path="/distributors" element={<Users />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders-archives" element={<OrderArchives />} />
      </Route>
    </Routes>
  );
};

export default ActionPanel;
