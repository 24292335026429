import PanelLayout from 'components/PanelLayout/PanelLayout';
import { Route, Routes } from 'react-router-dom';
import panelUserMenuItems from 'core/menu/panelUserMenuItems';
import BanersUser from './Baners/BanersUser';
import { useQuery } from 'react-query';
import { makeGetActionsAllActiveRequest } from 'core/services/actions';
import { LinearProgress } from '@mui/material';
import UserAction from './Action/Action';
import ActionOrders from '../shared/actionPanel/Orders/Orders';
import SendMessage from '../shared/SendMessage/SendMessage';
import ActionOrderArchives from '../shared/actionPanel/OrderArchives/OrderArchives';
import { USER_ACTIVE_ACTIONS } from 'core/Query';
import { FormattedMessage } from 'react-intl';

const UserPanel = () => {
  const dataQuery = useQuery(USER_ACTIVE_ACTIONS, makeGetActionsAllActiveRequest);
  const activeActions = dataQuery.data?.data.map((single) => ({ id: single.id, name: single.name })) || [];
  if (dataQuery.isLoading) return <LinearProgress />;
  return (
    <Routes>
      <Route
        element={
          <PanelLayout
            linkPath="/"
            disabledMenu={false}
            menu={panelUserMenuItems(activeActions)}
            title={<FormattedMessage id="APP.ACTIONS_PANEL" />}
          />
        }
      >
        <Route path="/" element={<BanersUser />} />
        <Route path="/action/:id" element={<UserAction />} />
        <Route path="/orders" element={<ActionOrders />} />
        <Route path="/orders-archives" element={<ActionOrderArchives />} />
        <Route path="/contact" element={<SendMessage />} />
      </Route>
    </Routes>
  );
};

export default UserPanel;
