import { Upload as ANTDUpload } from 'antd';
import { UploadProps } from './Upload.types';
import './upload.css';
import { FormattedMessage } from 'react-intl';
import { FormControl, FormHelperText } from '@mui/material';

const Upload = (props: UploadProps) => {
  const { images, setAddImages, setRemoveImages, setImages, maxCount, error, setError } = props;
  return (
    <FormControl>
      <ANTDUpload
        accept="image/*"
        customRequest={({ onSuccess }) => {
          if (onSuccess) onSuccess('ok');
        }}
        maxCount={maxCount}
        listType="picture-card"
        fileList={images}
        onRemove={(file) => {
          if ((file as any).id && setRemoveImages) {
            setRemoveImages((old: any) => [...old, (file as any).id]);
          }
        }}
        beforeUpload={(file) => {
          const isLt2M = file.size < 2000000;
          if (!isLt2M && setError) {
            setError('Zdjęcie nie może przekraczać 2mb');
          }

          return isLt2M;
        }}
        onChange={(props) => {
          if (props.file.size && props.file.size >= 2000000) return;
          if (setError) setError('');
          if (props.file.uid) {
            if (props.file.status === 'done' && setAddImages) {
              setAddImages((old: any) => [...old, props.file]);
            }
            if (props.file.status === 'removed' && setAddImages) {
              setAddImages((old: any) => old.filter((file: any) => file.uid !== props.file.uid));
            }
          }
          setImages(props.fileList);
        }}
      >
        <FormattedMessage id="COMMON.DRAG_FILE_OR_CLICK" />
      </ANTDUpload>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default Upload;
