import { Box, Grid, Stack, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import Logo from 'images/logo.png';
import { FormattedMessage, useIntl } from 'react-intl';
import { object, string, ref } from 'yup';
import { useShowError } from 'hooks/useShowError';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { makePostChangePasswordRequest } from 'core/services/oauth';
import { ChangePasswordForm } from './ChangePassword.type';
import { Link as RouterLink, useLocation, useNavigate, generatePath } from 'react-router-dom';
import useParamLocale from 'context/Locale/useParamLocale';
import { useLocale } from 'context/Locale/Locale';

const validationSchema = object({
  password: string()
    .required('OAUTH.CHANGE_PASSWORD.FORM.LOGIN.ERROR.REQUIRED')
    .min(6, 'OAUTH.CHANGE_PASSWORD.FORM.LOGIN.ERROR.MIN')
    .max(255, 'OAUTH.CHANGE_PASSWORD.FORM.LOGIN.ERROR.MAX'),
  passwordConfirmation: string().oneOf([ref('password')], 'OAUTH.CHANGE_PASSWORD.FORM.LOGIN.ERROR.CONFIRM'),
});

const ChangePassword = () => {
  const { locale } = useLocale();
  useParamLocale();
  const intl = useIntl();
  const { search } = useLocation();
  const navigation = useNavigate();
  const token = new URLSearchParams(search).get('token');
  const email = new URLSearchParams(search).get('email');
  const { enqueueSnackbar } = useSnackbar();
  const forgotMutation = useMutation(makePostChangePasswordRequest(token || '', email || ''), {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'OAUTH.CHANGE_PASSWORD.FORM.SUCCESS' }), { variant: 'success' });
      navigation('/login');
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'OAUTH.CHANGE_PASSWORD.FORM.ERROR' }), {
        variant: 'error',
      });
    },
  });
  const showError = useShowError();
  const initialValues: ChangePasswordForm = {
    password: '',
    passwordConfirmation: '',
  };

  const handleOnSubmit = (form: ChangePasswordForm) => {
    forgotMutation.mutate(form);
  };
  const formik = useFormik({ initialValues, validationSchema, onSubmit: handleOnSubmit });

  return (
    <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
      <Grid container item xs={12} md={5}>
        <Stack spacing={2} display="flex" flexDirection="column" alignItems="center" padding={2} sx={{ width: '100%' }}>
          <img src={Logo} alt="Logo" />
          <form onSubmit={formik.handleSubmit} style={{ width: '100%', maxWidth: 500, marginTop: 20 }}>
            <Stack spacing={2}>
              <TextField
                name="password"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={Boolean(showError('password', formik))}
                helperText={showError('password', formik)}
                variant="outlined"
                fullWidth
                label={<FormattedMessage id="OAUTH.CHANGE_PASSWORD.FORM.PASSWORD" />}
              />
              <TextField
                name="passwordConfirmation"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passwordConfirmation}
                error={Boolean(showError('passwordConfirmation', formik))}
                helperText={showError('passwordConfirmation', formik)}
                variant="outlined"
                fullWidth
                label={<FormattedMessage id="OAUTH.CHANGE_PASSWORD.FORM.PASSWORD_CONFIRMED" />}
              />
              <Button
                variant="text"
                color="inherit"
                component={RouterLink}
                to={generatePath('/login/:lang', { lang: locale })}
                sx={{ textAlign: 'center' }}
              >
                <FormattedMessage id="OAUTH.CHANGE_PASSWORD.BUTTON.LOGIN" />
              </Button>
              <Button type="submit" variant="contained" color="secondary">
                <FormattedMessage id="OAUTH.CHANGE_PASSWORD.BUTTON.SEND" />
              </Button>
            </Stack>
          </form>
        </Stack>
      </Grid>
    </Box>
  );
};

export default ChangePassword;
