import React from 'react';
import { Box, Grid, Stack, TextField, Typography, Button } from '@mui/material';
import { useFormik } from 'formik';
import Logo from 'images/logo.png';
import { FormattedMessage, useIntl } from 'react-intl';
import { object, string } from 'yup';
import { useShowError } from 'hooks/useShowError';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { makePostLoginRequest } from 'core/services/oauth';
import { LoginForm } from './Login.type';
import { isBackendError } from 'utils/backendError';
import { Link as RouterLink, useNavigate, generatePath } from 'react-router-dom';
import useParamLocale from 'context/Locale/useParamLocale';
import { useLocale } from 'context/Locale/Locale';

const validationSchema = object({
  login: string().required('OAUTH.LOGIN.FORM.LOGIN.ERROR.REQUIRED').email('OAUTH.LOGIN.FORM.LOGIN.ERROR.EMAIL'),
  password: string().required('OAUTH.LOGIN.FORM.PASSWORD.ERROR.REQUIRED'),
});

const Login = () => {
  const { locale } = useLocale();
  useParamLocale();
  const intl = useIntl();
  const navigation = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const loginMutation = useMutation(makePostLoginRequest, {
    onSuccess(response) {
      localStorage.setItem('token', response.data.token);
      navigation('/', { replace: true });
    },
    onError(error) {
      if (isBackendError(error)) {
        if (error.response?.status === 401) {
          enqueueSnackbar(intl.formatMessage({ id: 'OAUTH.LOGIN.FORM.BAD_LOGIN_OR_REQUEST' }), {
            variant: 'error',
          });
        }
      }
    },
  });
  const showError = useShowError();
  const initialValues: LoginForm = {
    login: '',
    password: '',
  };

  const handleOnSubmit = (form: LoginForm) => {
    loginMutation.mutate(form);
  };
  const formik = useFormik({ initialValues, validationSchema, onSubmit: handleOnSubmit });

  return (
    <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
      <Grid container item xs={12} md={6}>
        <Stack spacing={2} display="flex" flexDirection="column" alignItems="center" padding={2} sx={{ width: '100%' }}>
          <img src={Logo} alt="Logo" />
          <Typography variant="h4">
            <FormattedMessage id="OAUTH.LOGIN.TITLE" />
          </Typography>
          <Typography variant="h4">
            <FormattedMessage id="OAUTH.LOGIN.SUBTITLE" />
          </Typography>
          <form onSubmit={formik.handleSubmit} style={{ width: '100%', maxWidth: 500, marginTop: 20 }}>
            <Stack spacing={2}>
              <TextField
                name="login"
                autoComplete="login"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.login}
                error={Boolean(showError('login', formik))}
                helperText={showError('login', formik)}
                variant="outlined"
                fullWidth
                label={<FormattedMessage id="OAUTH.LOGIN.FORM.LOGIN" />}
              />
              <TextField
                name="password"
                autoComplete="password"
                variant="outlined"
                type="password"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={Boolean(showError('password', formik))}
                helperText={showError('password', formik)}
                label={<FormattedMessage id="OAUTH.LOGIN.FORM.PASSWORD" />}
              />
              <Button
                variant="text"
                color="inherit"
                component={RouterLink}
                to={generatePath('/forget-password/:lang', { lang: locale })}
                sx={{ textAlign: 'center' }}
              >
                <FormattedMessage id="OAUTH.LOGIN.BUTTON.FORGET_PASSWORD" />
              </Button>
              <Button type="submit" variant="contained" color="secondary">
                <FormattedMessage id="OAUTH.LOGIN.FORM.BUTTON_SEND" />
              </Button>
            </Stack>
          </form>
        </Stack>
      </Grid>
    </Box>
  );
};

export default Login;
