import { Box, Grid, Stack, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import Logo from 'images/logo.png';
import { FormattedMessage, useIntl } from 'react-intl';
import { object, string } from 'yup';
import { useShowError } from 'hooks/useShowError';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { makePostForgotRequest } from 'core/services/oauth';
import { ForgotPasswordForm } from './ForgetPassword.type';
import { Link as RouterLink, useNavigate, generatePath } from 'react-router-dom';
import useParamLocale from 'context/Locale/useParamLocale';
import { useLocale } from 'context/Locale/Locale';

const validationSchema = object({
  login: string().required('OAUTH.LOGIN.FORM.LOGIN.ERROR.REQUIRED').email('OAUTH.LOGIN.FORM.LOGIN.ERROR.EMAIL'),
});

const ForgetPassword = () => {
  const { locale } = useLocale();
  useParamLocale();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const navigation = useNavigate();
  const forgotMutation = useMutation(makePostForgotRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'OAUTH.FORGOT_PASSWORD.FORM.SUCCESS' }), { variant: 'success' });
      navigation('/login');
    },
    onError(error) {
      enqueueSnackbar(intl.formatMessage({ id: 'OAUTH.FORGOT_PASSWORD.FORM.ERROR' }), {
        variant: 'error',
      });
    },
  });
  const showError = useShowError();
  const initialValues: ForgotPasswordForm = {
    login: '',
  };

  const handleOnSubmit = (form: ForgotPasswordForm) => {
    forgotMutation.mutate(form);
  };
  const formik = useFormik({ initialValues, validationSchema, onSubmit: handleOnSubmit });

  return (
    <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
      <Grid container item xs={12} md={5}>
        <Stack spacing={2} display="flex" flexDirection="column" alignItems="center" padding={2} sx={{ width: '100%' }}>
          <img src={Logo} alt="Logo" />
          <form onSubmit={formik.handleSubmit} style={{ width: '100%', maxWidth: 500, marginTop: 20 }}>
            <Stack spacing={2}>
              <TextField
                name="login"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.login}
                error={Boolean(showError('login', formik))}
                helperText={showError('login', formik)}
                variant="outlined"
                fullWidth
                label={<FormattedMessage id="OAUTH.LOGIN.FORM.LOGIN" />}
              />
              <Button
                variant="text"
                color="inherit"
                component={RouterLink}
                to={generatePath('/login/:lang', { lang: locale })}
                sx={{ textAlign: 'center' }}
              >
                <FormattedMessage id="OAUTH.FORGET_PASSWORD.BUTTON.LOGIN" />
              </Button>
              <Button type="submit" variant="contained" color="secondary">
                <FormattedMessage id="OAUTH.FORGET_PASSWORD.BUTTON.SEND" />
              </Button>
            </Stack>
          </form>
        </Stack>
      </Grid>
    </Box>
  );
};

export default ForgetPassword;
