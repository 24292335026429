import React from 'react';
import { Grid, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import KammtActionsForm from 'components/KammtActions/Form';
import { useQuery } from 'react-query';
import { parseISO } from 'date-fns';
import { makeGetUsersDistributorRequest } from 'core/services/user';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { Paginate } from 'types/Paginate';
import PageHeader from 'components/PageHeader/PageHeader';
import useCacheQuery from 'hooks/useCacheQuery';
import Pagination from 'components/Pagination/Pagination';
import { makeGetActionsRequest } from 'core/services/actions';
import { Action } from 'types/Action';
import { ACTIONS, USERS_ONLY_DISTRIBUTOR } from 'core/Query';

const Actions = () => {
  const [page, setPage] = React.useState<number>(1);
  const [sortValue, setSortValue] = React.useState<string>('id');
  const actionsQuery = useQuery([ACTIONS, page, sortValue], () => makeGetActionsRequest(page, sortValue), {
    select(response) {
      return response.data;
    },
    onSuccess(data: Paginate<Action>) {
      if (data.data.length === 0 && page > 1) {
        setPage((old) => old - 1);
      }
    },
  });
  const { data, pages } = useCacheQuery({ data: actionsQuery.data?.data, total: actionsQuery.data?.total });
  const userQuery = useQuery(USERS_ONLY_DISTRIBUTOR, makeGetUsersDistributorRequest, {
    select(response) {
      return response.data.data;
    },
    refetchOnWindowFocus: false,
  });
  return (
    <Grid container spacing={2}>
      {(actionsQuery.isLoading || actionsQuery.isRefetching) && <FullPageLoader />}
      <PageHeader
        sortValue={sortValue}
        onChangeValue={setSortValue}
        sortItems={[
          { value: 'id', name: <FormattedMessage id="SORT_BY.ACTION_NO" /> },
          { value: 'start_date', name: <FormattedMessage id="SORT_BY.DATE_FROM" /> },
          { value: 'end_date', name: <FormattedMessage id="SORT_BY.DATE_TO" /> },
        ]}
      >
        <FormattedMessage id="ADMIN_ACTIONS.TITLE" />
      </PageHeader>
      {data?.map((single) => (
        <KammtActionsForm
          key={single.id}
          userIsLoading={userQuery.isLoading}
          name={single.name}
          initialValues={{
            ...single,
            startDate: parseISO(single.startDate),
            endDate: parseISO(single.endDate),
            selectedUsers: single.users.map((user) => ({ ...user, balance: user.pivot.balance })),
          }}
          actionUser={(userQuery.data as any) || []}
        />
      ))}
      {actionsQuery.isFetched && data?.length === 0 ? (
        <Grid item xs={12}>
          <Paper>
            <Box display="flex" justifyContent="center" padding={2}>
              <FormattedMessage id="ADMIN_ACTIONS.EMPTY" />
            </Box>
          </Paper>
        </Grid>
      ) : null}
      {data && <Pagination page={page} onChangePage={setPage} pages={pages} />}
    </Grid>
  );
};

export default Actions;
