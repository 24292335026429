import React from 'react';
import PanelLayout from 'components/PanelLayout/PanelLayout';
import { useUser } from 'context/User/User';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { UserRole } from 'types/Users';
import ChoosePanel from '../ChoosePanel/ChoosePanel.views';
import AdminSessionPanel from '../Admin/SessionPanel/SessionPanel';
import KamMtPanel from '../KamMt/SessionPanel/SessionPanel';
import ActionPanel from '../Admin/ActionPanel/ActionPanel';
import ActionKammtPanel from '../KamMt/ActionPanel/ActionPanel';
import UserPanel from '../UserPanel/UserPanel';

const MainPageViews = () => {
  const { email, role } = useUser();
  const navigate = useNavigate();
  const { id } = role;

  if (!email) {
    navigate('/login');
    return null;
  }
  const routes = () => {
    if (id === UserRole.AMIN) {
      return [
        {
          path: '/',
          layout: <PanelLayout linkPath="/" disabledMenu menu={undefined} />,
          element: <ChoosePanel />,
        },
        {
          path: '/sessions/*',
          element: <AdminSessionPanel />,
        },
        {
          path: '/actions/*',
          element: <ActionPanel />,
        },
      ];
    }
    if (id === UserRole.KAM_MT) {
      return [
        {
          path: '/',
          layout: <PanelLayout linkPath="/" disabledMenu menu={undefined} />,
          element: <ChoosePanel />,
        },
        {
          path: '/sessions/*',
          element: <KamMtPanel />,
        },
        {
          path: '/actions/*',
          element: <ActionKammtPanel />,
        },
      ];
    }
    return [
      {
        path: '/*',
        element: <UserPanel />,
      },
    ];
  };
  return (
    <Routes>
      {routes().map((route) => {
        if (route.layout)
          return (
            <Route key={route.path} element={route.layout}>
              <Route path={route.path} element={route.element} />
            </Route>
          );
        return <Route key={route.path} path={route.path} element={route.element} />;
      })}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default MainPageViews;
