import React from 'react';
import { differenceInDays, differenceInHours, differenceInMinutes, parseISO, addDays, addHours } from 'date-fns';
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props {
  endDate: string | undefined;
}

const useTimer = (props: Props) => {
  const { endDate } = props;
  const [now, setNow] = React.useState<Date>(new Date());
  const time = React.useMemo(() => {
    if (!endDate) return null;
    let diffDay = differenceInDays(parseISO(endDate), now);
    diffDay = diffDay > 0 ? diffDay : 0;
    let diffHours = differenceInHours(parseISO(endDate), addDays(now, diffDay));
    diffHours = diffHours > 0 ? diffHours : 0;
    let diffMinutes = differenceInMinutes(parseISO(endDate), addHours(addDays(now, diffDay), diffHours));
    diffMinutes = diffMinutes > 0 ? diffMinutes : 0;
    return (
      <Box component="span" sx={{ color: 'secondary.main' }}>
        <FormattedMessage id="KAM_MT_SESSION.TITLE.DAYS" values={{ count: diffDay }} />{' '}
        <FormattedMessage id="KAM_MT_SESSION.TITLE.HOURS" values={{ count: diffHours }} />{' '}
        <FormattedMessage id="KAM_MT_SESSION.TITLE.MINUTES" values={{ count: diffMinutes }} />
      </Box>
    );
  }, [endDate, now]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  return time;
};

export default useTimer;
