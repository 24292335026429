import { styled } from '@mui/material';

export const Fieldset = styled('fieldset')(({ theme }) => ({
  padding: 0,
  overflow: 'auto',
  marginTop: -10,
  border: '1px solid',
  borderColor: 'rgba(0, 0, 0, 0.23)',
  borderRadius: theme.shape.borderRadius,
  '&.error': {
    borderColor: theme.palette.error.main,
  },
}));

export const Legend = styled('legend')(({ theme }) => ({
  fontSize: '0.75em',
  marginLeft: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.6)',
  '&.error': {
    color: theme.palette.error.main,
  },
}));
