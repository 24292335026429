import { User } from './Users';

export enum MessageStatus {
  READ = 'read',
  UN_READ = 'un_read',
}

export interface Message {
  id: number;
  message: string;
  status: MessageStatus;
  createdAt: string;
  user: User;
}
