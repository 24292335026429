import React from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Tooltip,
  Stack,
  Popper,
  Paper,
  Button,
  MenuItem,
  Select,
} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import InfoIcon from '@mui/icons-material/Info';
import { ProductProps } from './Product.types';
import { FormattedMessage } from 'react-intl';
import { Order } from 'types/Order';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Product = (props: ProductProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<number>(0);
  const [slide, setSlaid] = React.useState<number>(0);
  const [distributorId, setDistributorId] = React.useState<number>(props.distributors ? props.distributors[0].id : 0);
  const inputRef = React.useRef<HTMLDivElement>(null);
  const showAmountRef = React.useRef<HTMLSpanElement>(null);
  const { product, orderedAmount } = props;
  const showTooltip = () => {
    setOpen(true);
  };
  React.useEffect(() => {
    if (!open) return;
    const timeout = setTimeout(() => {
      setOpen(false);
    }, 2000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [open]);

  const showAmount = React.useMemo(() => {
    const productAmount = orderedAmount.all;
    const userOrderAmount = orderedAmount.user;
    const userLimit = product.users[0]?.pivot.amount ? product.users[0].pivot.amount : productAmount;

    return userLimit - userOrderAmount;
  }, [product.users, orderedAmount]);

  const [, drag, preview] = useDrag(
    () => ({
      type: 'product',
      item: {
        id: product.id,
        name: product.name,
        image: product.images[0].url,
        shortDescription: product.shortDescription,
        price: product.price,
        amount: product.amount,
        selected: value,
      },
      canDrag: () => {
        if (value > 0 && value <= showAmount && null != props.balance && value * props.product.price <= props.balance) {
          return true;
        }
        showTooltip();
        return false;
      },
    }),
    [value, product, showAmount, props],
  );

  const handleAddOrder = (productPropsValue: ProductProps) => {
    if (
      productPropsValue.chooseAmount + value > props.product.amount ||
      (null != props.balance && value * productPropsValue.product.price > props.balance) ||
      value === 0
    ) {
      setOpen(true);
      return;
    }

    const order = {
      userId:
        null != props.distributors && props.distributors.length > 0
          ? distributorId
          : productPropsValue.product.actionId,
      product: {
        id: productPropsValue.product.id,
        name: productPropsValue.product.name,
        chooseAmount: value,
        price: productPropsValue.product.price,
        availableProductValue:
          (null != showAmountRef.current && +showAmountRef.current.innerText === 0) ||
          (null != productPropsValue.balance && productPropsValue.balance - productPropsValue.product.price === 0) ||
          (null != productPropsValue.balance &&
            productPropsValue.product.price > productPropsValue.balance - productPropsValue.product.price),
      },
    };

    if (null != props.onEmit) {
      props.onEmit(order);
    }
  };

  const onChangeValue = (element: number) => {
    setDistributorId(element);
  };

  const checkButtonDisableStatus = () => {
    if (
      props.chooseAmount >= props.product.amount ||
      props.balance === 0 ||
      (null != props.balance && props.product.price > props.balance)
    ) {
      if (null != props.orders) {
        const activeOrders = props.orders.filter((elem: Order) => elem.product.id === props.product.id);
        if (activeOrders) {
          activeOrders.forEach((elem) => {
            elem.product.availableProductValue = true;
          });
        }
      }
      return true;
    }
    if (null != props.orders) {
      const activeOrders = props.orders.filter((elem: Order) => elem.product.id === props.product.id);
      if (activeOrders) {
        activeOrders.forEach((elem) => {
          elem.product.availableProductValue = false;
        });
      }
    }
    return false;
  };

  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);
  return (
    <Card
      ref={drag}
      sx={{
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText',
        width: '178px',
        minWidth: '178px',
        maxWidth: '178px',
        overflow: 'hidden',
        position: 'relative',
      }}
      onClick={() =>
        setOpen(
          !(value > 0 && null != value) ||
            (value > 0 && showAmount < value) ||
            (null != props.balance && value * props.product.price > props.balance),
        )
      }
    >
      <AutoPlaySwipeableViews index={slide} onChangeIndex={(value) => setSlaid(value)} enableMouseEvents>
        {product.images.map((image) => (
          <div
            key={image.url}
            style={{ height: '202px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <img src={image.url} alt="product" style={{ objectFit: 'cover', height: '100%', minWidth: '100%' }} />
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Tooltip title={product.description}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#0F7160',
            color: '#FFFFFF',
            fontSize: '12px',
            padding: '16px 8px',
            cursor: 'pointer',
          }}
        >
          Info
        </div>
      </Tooltip>
      <CardContent sx={{ textAlign: 'center' }}>
        <Stack spacing={0.5} alignItems="center">
          <Typography variant="body1">{product.name}</Typography>
          <Typography variant="subtitle2">{product.shortDescription}</Typography>
          <Typography variant="h6">{product.price} pt</Typography>
          <TextField
            ref={inputRef}
            variant="outlined"
            inputProps={{ min: 0, max: showAmount }}
            value={value}
            onChange={(event) => {
              const newValue = parseInt(event.target.value, 10);
              if (isNaN(newValue)) return setValue(0);
              if (newValue > showAmount) return setValue(showAmount);
              if (newValue < 0) return setValue(0);
              setValue(newValue);
            }}
            size="small"
            type="number"
            onClick={(event) => {
              event.stopPropagation();
            }}
            sx={{ backgroundColor: '#fff', width: '70px', borderRadius: '4px' }}
          />
          <Popper open={open} anchorEl={inputRef.current}>
            <Paper sx={{ p: 1 }}>
              <Stack direction="row" spacing={1}>
                <InfoIcon color="secondary" />
                <Typography>
                  {showAmount === 0
                    ? 'Produkt został wyczerpany'
                    : null != props.balance && props.balance <= props.product.price * props.chooseAmount
                    ? 'Nie posiadasz wystarczających środków'
                    : 'Wprowadź poprawną ilość sztuk'}
                </Typography>
              </Stack>
            </Paper>
          </Popper>
          <Typography variant="subtitle2">
            <span ref={showAmountRef}>{showAmount}</span> <FormattedMessage id="PRODUCT.AVAILABLE" />
          </Typography>
          {null != props.distributors && props.distributors.length > 0 && (
            <>
              <FormattedMessage id="USERS.FORM.DISTRIBUTOR" />
              <Select
                value={distributorId}
                variant="outlined"
                onChange={(_, element: any) => onChangeValue(element.props.value)}
                sx={{
                  minWidth: 150,
                  '.MuiOutlinedInput-input': { backgroundColor: '#fff', padding: '4px' },
                }}
              >
                {null != props.distributors &&
                  props.distributors.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}
          <Button
            color="primary"
            disabled={checkButtonDisableStatus()}
            variant="contained"
            onClick={() => handleAddOrder(props)}
          >
            <FormattedMessage id="COMMON.ORDER.ADD" />
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Product;
