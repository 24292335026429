import React from 'react';
import { Grid, Paper, TextField, Stack, CircularProgress, IconButton, Typography } from '@mui/material';
import './upload.css';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { FormattedMessage, useIntl } from 'react-intl';
import { BanerFormProps } from './Form.types';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useShowError } from 'hooks/useShowError';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { makePostBanerRequest } from 'core/services/baners';
import { ADMIN_BANERS } from 'core/Query';
import Upload from 'components/Upload/Upload';

const validationSchema = object().shape({
  url: string()
    .url('ADMIN.BANER.FORM.URL.ERROR.TYPE')
    .required('ADMIN.BANER.FORM.URL.ERROR.REQUIRED')
    .max(100, 'ADMIN.BANER.FORM.URL.ERROR.MAX'),
});

const AdminBanerForm = (props: BanerFormProps) => {
  const [images, setImages] = React.useState<any[]>(
    props.initialValues.image ? [{ url: props.initialValues.image }] : [],
  );
  const [uploadError, setUploadError] = React.useState<string | undefined>(undefined);
  const { initialValues, closeAdd } = props;
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const id = initialValues.id;
  const showError = useShowError();
  const mutationPost = useMutation(makePostBanerRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN.BANER.ADD.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries(ADMIN_BANERS);
      if (closeAdd) closeAdd();
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN.BANER.ADD.ERROR' }), { variant: 'error' });
    },
  });

  const handleSubmitSave = (form: any) => {
    if (images.length === 0) {
      setUploadError(intl.formatMessage({ id: 'ADMIN.BANER.FORM.IMAGE.ERROR.REQUIRED' }));
      return;
    }
    const formData = new FormData();
    formData.append('image', images[0].originFileObj);
    formData.append('url', form.url);
    mutationPost.mutate(formData);
  };
  const formik = useFormik({ initialValues, onSubmit: handleSubmitSave, validationSchema });
  const isLoading = mutationPost.isLoading || props.deleteLoading;

  return (
    <Grid item xs={12}>
      <Paper component="form" onSubmit={formik.handleSubmit}>
        <Grid container padding={1}>
          <Grid display="flex" justifyContent="flex-end" item xs={12}>
            <Stack direction="row" spacing={1} alignItems="center">
              {!props.editMode && (
                <Typography variant="subtitle2" color="error">
                  <FormattedMessage id="COMMON.NOT_SAVE" />
                </Typography>
              )}
              <IconButton disabled={isLoading} onClick={() => props.handleDelete(id)}>
                {props.deleteLoading ? <CircularProgress size={20} color="secondary" /> : <DeleteIcon />}
              </IconButton>
              {!props.editMode && (
                <IconButton disabled={isLoading} type="submit">
                  {mutationPost.isLoading ? <CircularProgress size={20} color="secondary" /> : <SaveIcon />}
                </IconButton>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} padding={1}>
            <TextField
              name="url"
              value={formik.values.url}
              disabled={isLoading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="https://katrin.com"
              error={Boolean(showError('url', formik))}
              helperText={showError('url', formik)}
              fullWidth
              label="Adres url"
            />
          </Grid>
          <Grid item xs={12}>
            <Upload images={images} maxCount={1} setImages={setImages} error={uploadError} setError={setUploadError} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default AdminBanerForm;
