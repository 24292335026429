import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';

const PageHeaderNoSort: React.FunctionComponent = (props) => {
  const { children } = props;
  return (
    <Grid item xs={12}>
      <Paper>
        <Box display="flex" sx={(theme) => ({ [theme.breakpoints.down('md')]: { flexDirection: 'column' } })}>
          <Box display="flex" alignItems="center" padding={1} flex={1}>
            <Typography variant="h6">{children}</Typography>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default PageHeaderNoSort;
