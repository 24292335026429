import { CSSProperties } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { Card, CardContent, Stack, Typography } from '@mui/material';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

const ProductDrop = () => {
  const { item, initialOffset, currentOffset, isDragging } = useDragLayer((monitor) => {
    return {
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    };
  });

  if (!isDragging) return null;

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <Card
          sx={{
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
            width: '178px',
            minWidth: '178px',
            maxWidth: '178px',
            overflow: 'hidden',
            position: 'relative',
            opacity: 0.5,
          }}
        >
          <div style={{ height: '202px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={item.image} alt="product" style={{ objectFit: 'cover', height: '100%', minWidth: '100%' }} />
          </div>
          <CardContent sx={{ textAlign: 'center' }}>
            <Stack spacing={0.5} alignItems="center">
              <Typography variant="body1">{item.name}</Typography>
              <Typography variant="subtitle2">{item.shortDescription}</Typography>
              <Typography variant="h6">{item.price} pt</Typography>
              <Typography variant="h6">{item.selected} szt.</Typography>
            </Stack>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ProductDrop;
