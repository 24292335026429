import { LinearProgress as MuiLinearProgress } from '@mui/material';
import { Box } from '@mui/system';

const LinearProgress = () => (
  <Box sx={{ width: '100%', position: 'fixed', left: 0, top: 0 }}>
    <MuiLinearProgress />
  </Box>
);

export default LinearProgress;
