import { Menu } from './menu';

const orderPanelKamMtMenuItems = (session: { id: number; name: string }[]): Menu[] => {
  const submenu =
    session.length > 0
      ? session.map((single) => ({
          title: 'ORDER_PANEL_KAM_MT_MENU.SESSION',
          titleValues: { session: single.name },
          path: `/panel/sessions/asign-order/${single.id}`,
        }))
      : [{ title: 'ORDER_PANEL_KAM_MT_MENU.SESSIONS.EMPTY', path: `/panel/sessions/empty`, disabled: true }];
  return [
    {
      title: 'ORDER_PANEL_KAM_MT_MENU.SESSIONS',
      path: '/panel/sessions/',
      submenu,
    },
    {
      title: 'ORDER_PANEL_KAM_MT_MENU.ORDERS',
      path: '/panel/sessions/orders',
    },
    {
      title: 'ORDER_PANEL_KAM_MT_MENU.USERS',
      path: '/panel/sessions/distributors',
    },
    {
      title: 'ORDER_PANEL_KAM_MT_MENU.HISTORY',
      path: '/panel/sessions/orders-archives',
    },
    {
      title: 'ORDER_PANEL_KAM_MT_MENU.MESSAGE',
      path: '/panel/sessions/message',
    },
    {
      title: 'ORDER_PANEL_KAM_MT_MENU.VOUCHERS',
      path: '/panel/sessions/vouchers',
    },
  ];
};

export default orderPanelKamMtMenuItems;
