import React from 'react';
import { useQuery } from 'react-query';
import { Me } from 'types/Users';
import { makeGetMeRequest } from 'core/services/user';
import LinearProgress from 'components/LinearProgress/LinearProgress';
import { useLocale } from 'context/Locale/Locale';

const UserContext = React.createContext<Me | undefined>(undefined);

export const useUser = () => {
  const user = React.useContext(UserContext);
  if (!user) throw new Error('use hook with context');
  return user;
};

const UserProvider: React.FunctionComponent = ({ children }) => {
  const { changeLocale } = useLocale();
  const { data, isLoading } = useQuery('me', makeGetMeRequest, {
    select(response) {
      return response.data;
    },
    onSuccess(data: Me) {
      changeLocale(data.lang);
    },
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    retry: 1,
  });
  return (
    <UserContext.Provider value={data}>
      {isLoading && <LinearProgress />}
      {!isLoading && children}
    </UserContext.Provider>
  );
};

export default UserProvider;
