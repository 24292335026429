import { Grid, Paper, Pagination as MuiPagination } from '@mui/material';
import { Box } from '@mui/system';
import { PaginationProps } from './Pagination.types';

const Pagination = (props: PaginationProps) => {
  return (
    <Grid item xs={12}>
      <Paper>
        <Box display="flex" justifyContent="center" padding={2}>
          <MuiPagination
            page={props.page}
            onChange={(_, page) => props.onChangePage(page)}
            count={props.pages}
            variant="outlined"
            color="secondary"
          />
        </Box>
      </Paper>
    </Grid>
  );
};

export default Pagination;
