import React from 'react';
import { Grid, Paper, Button, TextField } from '@mui/material';
import trim from 'lodash/trim';
import { Box } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { useSnackbar } from 'notistack';
import PageHeaderNoSort from 'components/PageHeaderNoSort/PageHeader';
import { makePostMessagesRequest } from 'core/services/message';

const SendMessage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = React.useState<string>('');
  const [messageIsEmpty, setMessageIsEmpty] = React.useState<boolean>(false);
  const intl = useIntl();

  const mutatuinSend = useMutation(makePostMessagesRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'MESSAGE.SEND.SUCCESS' }), { variant: 'success' });
      setMessage('');
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'MESSAGE.SEND.ERROR' }), { variant: 'error' });
    },
  });

  const handleSendMessage = () => {
    if (!trim(message)) {
      setMessageIsEmpty(true);
      return;
    }
    mutatuinSend.mutate(message);
  };

  return (
    <Grid container spacing={2}>
      {mutatuinSend.isLoading && <FullPageLoader />}
      <PageHeaderNoSort>
        <FormattedMessage id="MESSAGE.TITLE" />
      </PageHeaderNoSort>
      <Grid item xs={12}>
        <Paper>
          <Box padding={2}>
            <TextField
              multiline
              value={message}
              error={messageIsEmpty}
              onChange={(event) => {
                setMessage(event.target.value);
                setMessageIsEmpty(false);
              }}
              minRows={20}
              maxRows={20}
              helperText={messageIsEmpty && <FormattedMessage id="MESSAGE.FORM.IS_EMPTY" />}
              fullWidth
              label={<FormattedMessage id="MESSAGE.LABEL" />}
            />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Button color="secondary" variant="contained" onClick={handleSendMessage}>
            <FormattedMessage id="MESSAGE.BUTTON.SEND" />
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SendMessage;
