import React from 'react';
import { Grid } from '@mui/material';
import { useQuery } from 'react-query';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { makeGetBanersRequest } from 'core/services/baners';
import useGlobalErrors from 'hooks/useGlobalErrors/useGlobalErrors';
import { USER_BANERS } from 'core/Query';

const Baners = () => {
  const { globalFormatErrors, hasError, setErrors } = useGlobalErrors();
  const baners = useQuery(USER_BANERS, makeGetBanersRequest, {
    select(response) {
      return response.data;
    },
    onError() {
      setErrors(['USER.BANERS.LOAD.ERROR']);
    },
  });
  React.useEffect(() => {
    if (!hasError && baners.data?.length === 0) {
      setErrors(['USER.BANERS.LOAD.EMPTY']);
    }
  }, [hasError, baners.data, setErrors]);
  return (
    <Grid container spacing={2}>
      {baners.isLoading && <FullPageLoader />}
      {globalFormatErrors}
      {baners.data?.map((baner) => (
        <Grid key={`${baner.url}-${baner.fullPath}`} item xs={12}>
          <a href={baner.url} target="_blank" rel="noreferrer">
            <img style={{ maxWidth: '100%' }} src={baner.fullPath} alt="" />
          </a>
        </Grid>
      ))}
    </Grid>
  );
};

export default Baners;
