import React from 'react';
import { Box, Stack, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const ChoosePanel = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={(theme) => ({
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        [theme.breakpoints.up('sm')]: {
          minHeight: `calc(100vh - 64px)`,
        },
      })}
    >
      <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1}>
        <Stack direction={{ xs: 'column', md: 'row' }} flexWrap="wrap" spacing={2} sx={{ maxWidth: '100%' }}>
          <Button
            component={Link}
            to="/panel/sessions"
            variant="contained"
            color="secondary"
            sx={{ height: 150, width: 320, maxWidth: '100%', textAlign: 'center' }}
          >
            <FormattedMessage id="CHOOSE_PANEL.BUTTTON.ORDER_PANEL" />
          </Button>
          <Button
            component={Link}
            to="/panel/actions"
            variant="contained"
            color="secondary"
            sx={{ height: 150, width: 320, maxWidth: '100%', textAlign: 'center' }}
          >
            <FormattedMessage id="CHOOSE_PANEL.BUTTTON.AGENCY_PANEL" />
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default ChoosePanel;
