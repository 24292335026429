import axios from 'core/clients/api';
import { VoucherForm } from 'components/KamMtVoucher/Form.types';
import { Voucher } from 'types/Voucher';
import { Paginate } from 'types/Paginate';

const api = process.env.REACT_APP_API_URL;

export const makePostVoucherRequest = (user: VoucherForm) => axios.post<Voucher>(`${api}/vouchers`, user);

export const makePutVoucherRequest = (id: number) => (user: VoucherForm) =>
  axios.put<Voucher>(`${api}/vouchers/${id}`, user);

export const makeDeleteVoucherRequest = (id: number) => axios.delete<void>(`${api}/vouchers/${id}`);

export const makeGetVouchersRequest = (page: number) =>
  axios.get<Paginate<Voucher>>(`${api}/vouchers?page=${page}&perPage=10`);

export const makePutVoucherChangeStatusRequest = (params: { id: number; status: string }) =>
  axios.put<void>(`${api}/vouchers/${params.id}/change-status`, { status: params.status });
