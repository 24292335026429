import React from 'react';
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { Paginate } from 'types/Paginate';
import useCacheQuery from 'hooks/useCacheQuery';
import Pagination from 'components/Pagination/Pagination';
import { useSnackbar } from 'notistack';
import { OrderStatus } from 'types/Order';
import formatStatus from 'utils/formatStatus';
import { VOUCHERS } from 'core/Query';
import formatAddress from 'utils/formatAddress';
import PageHeaderNoSort from 'components/PageHeaderNoSort/PageHeader';
import { Voucher } from 'types/Voucher';
import { makeGetVouchersRequest, makePutVoucherChangeStatusRequest } from 'core/services/voucher';

const AdminOrders = () => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [page, setPage] = React.useState<number>(1);
  const queryClient = useQueryClient();
  const voucherQuery = useQuery([VOUCHERS, page], () => makeGetVouchersRequest(page), {
    select(response): Paginate<Voucher> {
      return response.data;
    },
    onSuccess(data: Paginate<Voucher>) {
      if (data.data.length === 0 && page > 1) {
        setPage((old) => old - 1);
      }
    },
  });
  const { data, pages } = useCacheQuery({ data: voucherQuery.data?.data, total: voucherQuery.data?.total });
  const mutationChangeStatus = useMutation(makePutVoucherChangeStatusRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'ORDER.CHANGE_STATUS.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries([VOUCHERS]);
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'ORDER.CHANGE_STATUS.ERROR' }), { variant: 'error' });
    },
  });
  const handleChangeStatus = (event: SelectChangeEvent<string>, id: number) => {
    mutationChangeStatus.mutate({ id, status: event.target.value });
  };
  return (
    <Grid container spacing={2}>
      {(voucherQuery.isLoading || voucherQuery.isRefetching) && <FullPageLoader />}
      <PageHeaderNoSort>
        <FormattedMessage id="VOUCHERS.ADMIN.TITLE" />
      </PageHeaderNoSort>
      <Grid item xs={12}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="VOUCHERS.ADMIN.PROMOTION_NAME" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="VOUCHERS.ADMIN.AMOUNT" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="VOUCHERS.ADMIN.TYPE" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="VOUCHERS.ADMIN.ORDER" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="VOUCHERS.ADMIN.SHIP_ADDRESS" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="VOUCHERS.ADMIN.STATUS" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((single) => (
                  <TableRow key={single.id}>
                    <TableCell>{single.promotionName}</TableCell>
                    <TableCell>{single.amount} pt</TableCell>
                    <TableCell>
                      <FormattedMessage id={`VOUCHER.FORM.TYPE.${single.type.toLocaleUpperCase()}`} />
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{single.user.name}</Typography>
                      <Typography variant="body2">
                        {single.user.street} {formatAddress(single.user.houseNumber, single.user.flatNumber)}
                      </Typography>
                      <Typography variant="body2">
                        {single.user.zipCode} {single.user.city}
                      </Typography>
                      <Typography variant="body2">{single.user.phone}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{single.company}</Typography>
                      <Typography variant="body2">
                        {single.street} {formatAddress(single.houseNumber, single.flatNumber)}
                      </Typography>
                      <Typography variant="body2">
                        {single.zipCode} {single.city}
                      </Typography>
                      <Typography variant="body2">{single.phone}</Typography>
                    </TableCell>
                    <TableCell>
                      <Select
                        size="small"
                        defaultValue={single.status}
                        onChange={(event) => handleChangeStatus(event, single.id)}
                      >
                        <MenuItem disabled value="pending">
                          {formatStatus(OrderStatus.CREATED)}
                        </MenuItem>
                        <MenuItem value="during">{formatStatus(OrderStatus.DURING)}</MenuItem>
                        <MenuItem value="finished">{formatStatus(OrderStatus.FINISH)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                ))}
                {voucherQuery.isFetched && data?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <FormattedMessage id="VOUCHERS.AMIN.EMPTY" />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      {data && <Pagination page={page} onChangePage={setPage} pages={pages} />}
    </Grid>
  );
};

export default AdminOrders;
