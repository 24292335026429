import React from 'react';
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { Paginate } from 'types/Paginate';
import PageHeader from 'components/PageHeader/PageHeader';
import useCacheQuery from 'hooks/useCacheQuery';
import Pagination from 'components/Pagination/Pagination';
import { OrderListArchive } from 'types/Order';
import { format, parseISO } from 'date-fns';
import { makeGetActionOrderArchiveRequest } from 'core/services/actionOrderArchives';
import { ACTIONS_ORDERS_ARCHIVES } from 'core/Query';
import formatAddress from 'utils/formatAddress';

const ActionOrderArchives = () => {
  const [page, setPage] = React.useState<number>(1);
  const [sortValue, setSortValue] = React.useState<string>('id');
  const orderQuery = useQuery(
    [ACTIONS_ORDERS_ARCHIVES, page, sortValue],
    () => makeGetActionOrderArchiveRequest(page, sortValue),
    {
      select(response) {
        return response.data;
      },
      onSuccess(data: Paginate<OrderListArchive>) {
        if (data.data.length === 0 && page > 1) {
          setPage((old) => old - 1);
        }
      },
    },
  );
  const { data, pages } = useCacheQuery({ data: orderQuery.data?.data, total: orderQuery.data?.total });

  return (
    <Grid container spacing={2}>
      {(orderQuery.isLoading || orderQuery.isRefetching) && <FullPageLoader />}
      <PageHeader
        sortValue={sortValue}
        onChangeValue={setSortValue}
        sortItems={[{ value: 'id', name: <FormattedMessage id="SORT_BY.ORDER_NO" /> }]}
      >
        <FormattedMessage id="KAM_MT.ORDER.TITLE" />
      </PageHeader>
      <Grid item xs={12}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="ORDER_ARCHIVES.TABLE.ACTION_NAME" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_ARCHIVES.TABLE.ACTION_ID" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_ARCHIVES.TABLE.PRODUCTS" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_ARCHIVES.TABLE.PRICE" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_ARCHIVES.TABLE.COMPANY" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_ARCHIVES.TABLE.ADDRESS" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_ARCHIVES.TABLE.STATUS" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_ARCHIVES.TABLE.DATE" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((single) => (
                  <TableRow key={single.id}>
                    <TableCell>{single.sessionName || single.actionName}</TableCell>
                    <TableCell>{single.orderId}</TableCell>
                    <TableCell>
                      {single.products.map((product) => (
                        <Typography key={product.name} variant="body2">
                          {product.name} - {product.amount} szt.
                        </Typography>
                      ))}
                    </TableCell>
                    <TableCell>
                      {single.products.reduce((old, curr) => {
                        const value = curr.price * curr.amount;
                        return old + value;
                      }, 0)}{' '}
                      pt
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{single.distributorCompany}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{single.distributorName}</Typography>
                      <Typography variant="body2">
                        {single.distributorStreet}{' '}
                        {formatAddress(single.distributorHouseNumber, single.distributorFlatNumber)}
                      </Typography>
                      <Typography variant="body2">
                        {single.distributorZipCode} {single.distributorCity}
                      </Typography>
                      <Typography variant="body2">{single.distributorPhone}</Typography>
                      <Typography variant="body2">{single.distributorEmail}</Typography>
                      {single.details && (
                        <>
                          <hr />
                          <Typography variant="body2">{single.details}</Typography>
                        </>
                      )}
                    </TableCell>
                    <TableCell>Zakończony</TableCell>
                    <TableCell>{format(parseISO(single.orderDate), 'yyyy-MM-dd')}</TableCell>
                  </TableRow>
                ))}
                {orderQuery.isFetched && data?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <FormattedMessage id="COMMON.ORDERS.ARCHIVE.EMPTY" />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      {data && <Pagination page={page} onChangePage={setPage} pages={pages} />}
    </Grid>
  );
};

export default ActionOrderArchives;
