import React from 'react';
import { useParams } from 'react-router-dom';
import { isLang } from 'types/Lang';
import { useLocale } from './Locale';

const useParamLocale = () => {
  const { lang } = useParams();
  const { changeLocale } = useLocale();
  React.useEffect(() => {
    if (isLang(lang)) {
      changeLocale(lang);
    }
  }, [changeLocale, lang]);
};

export default useParamLocale;
