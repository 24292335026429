import { Menu } from './menu';

const actionPanelKammtMenuItems: Menu[] = [
  {
    title: 'ACTION_PANEL_ADMIN_MENU.ACTIONS',
    path: '/panel/actions/',
  },
  {
    title: 'ORDER_PANEL_KAM_MT_MENU.USERS',
    path: '/panel/actions/distributors',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.ORDERS',
    path: '/panel/actions/orders',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.ORDERS_HISTORY',
    path: '/panel/actions/orders-archives',
  },
];

export default actionPanelKammtMenuItems;
