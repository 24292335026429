import {
  Grid,
  Paper,
  TextField,
  Stack,
  CircularProgress,
  IconButton,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { FormattedMessage, useIntl } from 'react-intl';
import { VoucherForm, VoucherFormProps } from './Form.types';
import { useFormik } from 'formik';
import { object, string, number } from 'yup';
import { useShowError } from 'hooks/useShowError';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { VOUCHERS } from 'core/Query';
import { makePostVoucherRequest, makePutVoucherRequest } from 'core/services/voucher';
const validationSchema = object().shape({
  name: string().required('VOUCHER.FORM.NAME.ERROR.REQUIRED').max(255, 'VOUCHER.FORM.NAME.ERROR.MAX'),
  phone: string().required('VOUCHER.FORM.PHONE.ERROR.REQUIRED').max(255, 'VOUCHER.FORM.PHONE.ERROR.MAX'),
  street: string().required('VOUCHER.FORM.STREET.ERROR.REQUIRED').max(255, 'VOUCHER.FORM.STREET.ERROR.MAX'),
  amount: number()
    .typeError('VOUCHER.AMOUNT.ERROR.TYPE')
    .integer('VOUCHER.AMOUNT.ERROR.INTIGER')
    .required('VOUCHER.AMOUNT.ERROR.REQUIRED')
    .min(1, 'VOUCHER.AMOUNT.ERROR.MIN')
    .max(1000000, 'VOUCHER.AMOUNT.ERROR.MAX'),
  type: string().required('VOUCHER.FORM.TYPE.ERROR.REQUIRED'),
  houseNumber: string()
    .required('VOUCHER.FORM.HOUSE_NUMBER.ERROR.REQUIRED')
    .max(20, 'VOUCHER.FORM.HOUSE_NUMBER.ERROR.MAX'),
  flatNumber: string().nullable().max(20, 'VOUCHER.FORM.FLAT_NUMBER.ERROR.MAX'),
  zipCode: string().required('VOUCHER.FORM.ZIP_CODE.ERROR.REQUIRED').max(20, 'VOUCHER.FORM.ZIP_CODE.ERROR.MAX'),
  city: string().required('VOUCHER.FORM.CITY.ERROR.REQUIRED').max(255, 'VOUCHER.FORM.CITY.ERROR.MAX'),
  company: string().required('VOUCHER.FORM.COMPANY.ERROR.REQUIRED').max(255, 'VOUCHER.FORM.COMPANY.ERROR.MAX'),
  promotionName: string()
    .required('VOUCHER.FORM.PROMOTION_NAME.ERROR.REQUIRED')
    .max(255, 'VOUCHER.FORM.PROMOTION_NAME.ERROR.MAX'),
});

const KamMtVoucherForm = (props: VoucherFormProps) => {
  const { initialValues, closeAdd, handleDelete, deleteIsLoading, edit } = props;
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const id = initialValues.id;
  const showError = useShowError();
  const mutationPost = useMutation(makePostVoucherRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'VOUCHER.ADD.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries([VOUCHERS]);
      if (closeAdd) closeAdd();
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'VOUCHER.ADD.ERROR' }), { variant: 'error' });
    },
  });
  const mutationPut = useMutation(makePutVoucherRequest(id as number), {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'VOUCHER.EDIT.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries([VOUCHERS]);
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'VOUCHER.EDIT.ERROR' }), { variant: 'error' });
    },
  });
  const handleSubmitSave = (form: VoucherForm) => {
    if (id) {
      mutationPut.mutate(form);
      return;
    }
    mutationPost.mutate(form);
  };

  const formik = useFormik({ initialValues, onSubmit: handleSubmitSave, validationSchema });
  const isLoading = mutationPost.isLoading || mutationPut.isLoading || deleteIsLoading;
  const saveLoading = mutationPost.isLoading || mutationPut.isLoading;
  const disabled = props.status !== 'pending';
  return (
    <Grid item xs={12}>
      <Paper component="form" onSubmit={formik.handleSubmit}>
        <Grid container padding={1}>
          {!disabled && (
            <Grid display="flex" justifyContent="flex-end" item xs={12}>
              <Stack direction="row" spacing={1} alignItems="center">
                {!edit && (
                  <Typography variant="subtitle2" color="error">
                    <FormattedMessage id="COMMON.NOT_SAVE" />
                  </Typography>
                )}
                <IconButton disabled={isLoading} onClick={() => handleDelete(id)}>
                  {deleteIsLoading ? <CircularProgress size={20} color="secondary" /> : <DeleteIcon />}
                </IconButton>
                <IconButton disabled={isLoading} type="submit">
                  {saveLoading ? <CircularProgress size={20} color="secondary" /> : <SaveIcon />}
                </IconButton>
              </Stack>
            </Grid>
          )}
          <Grid item xs={12} md={4} padding={1}>
            <Stack spacing={2} sx={{ height: '100%' }}>
              <TextField
                name="amount"
                value={formik.values.amount}
                disabled={isLoading || disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('amount', formik))}
                helperText={showError('amount', formik)}
                fullWidth
                label={<FormattedMessage id="VOUCHER.FORM.AMOUNT" />}
              />
              <FormControl sx={{ flex: 1 }}>
                <FormLabel>
                  <FormattedMessage id="VOUCHER.FORM.TYPE" />
                </FormLabel>
                <RadioGroup
                  name="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    disabled={isLoading || disabled}
                    value="card"
                    control={<Radio size="small" />}
                    label={<FormattedMessage id="VOUCHER.FORM.TYPE.CARD" />}
                  />
                  <FormControlLabel
                    value="coupon"
                    disabled={isLoading || disabled}
                    control={<Radio size="small" />}
                    label={<FormattedMessage id="VOUCHER.FORM.TYPE.COUPON" />}
                  />
                  {Boolean(showError('type', formik)) && (
                    <FormHelperText error>{showError('type', formik)}</FormHelperText>
                  )}
                </RadioGroup>
              </FormControl>
              <TextField
                name="promotionName"
                value={formik.values.promotionName}
                disabled={isLoading || disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('promotionName', formik))}
                helperText={showError('promotionName', formik)}
                fullWidth
                label={<FormattedMessage id="VOUCHER.FORM.PROMOTION_NAME" />}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} padding={1}>
            <Stack spacing={2}>
              <TextField
                name="company"
                value={formik.values.company}
                disabled={isLoading || disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('company', formik))}
                helperText={showError('company', formik)}
                fullWidth
                label={<FormattedMessage id="VOUCHER.FORM.COMPANY" />}
              />
              <TextField
                name="name"
                value={formik.values.name}
                disabled={isLoading || disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('name', formik))}
                helperText={showError('name', formik)}
                fullWidth
                label={<FormattedMessage id="VOUCHER.FORM.NAME" />}
              />
              <TextField
                name="phone"
                value={formik.values.phone}
                disabled={isLoading || disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('phone', formik))}
                helperText={showError('phone', formik)}
                fullWidth
                label={<FormattedMessage id="VOUCHER.FORM.PHONE" />}
              />
              <TextField
                name="street"
                value={formik.values.street}
                disabled={isLoading || disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('street', formik))}
                helperText={showError('street', formik)}
                fullWidth
                label={<FormattedMessage id="VOUCHER.FORM.STREET" />}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} padding={1}>
            <Stack spacing={2}>
              <TextField
                name="houseNumber"
                value={formik.values.houseNumber}
                disabled={isLoading || disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('houseNumber', formik))}
                helperText={showError('houseNumber', formik)}
                fullWidth
                label={<FormattedMessage id="VOUCHER.FORM.HOUSE_NUMBER" />}
              />
              <TextField
                name="flatNumber"
                value={formik.values.flatNumber}
                disabled={isLoading || disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('flatNumber', formik))}
                helperText={showError('flatNumber', formik)}
                fullWidth
                label={<FormattedMessage id="VOUCHER.FORM.FLAT_NUMBER" />}
              />
              <TextField
                name="zipCode"
                value={formik.values.zipCode}
                disabled={isLoading || disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('zipCode', formik))}
                helperText={showError('zipCode', formik)}
                fullWidth
                label={<FormattedMessage id="VOUCHER.FORM.ZIP_CODE" />}
              />
              <TextField
                name="city"
                value={formik.values.city}
                disabled={isLoading || disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('city', formik))}
                helperText={showError('city', formik)}
                fullWidth
                label={<FormattedMessage id="VOUCHER.FORM.CITY" />}
              />
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default KamMtVoucherForm;
