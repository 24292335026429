import React from 'react';
import { Paginate } from 'types/Paginate';

interface CacheQueryProps<T> {
  data?: T[];
  total?: number;
}

const useCacheQuery = <T,>(props: CacheQueryProps<T>) => {
  const cachedData = React.useRef<Paginate<T> | undefined>(undefined);
  if (props.data && props.total) cachedData.current = { data: props.data, total: props.total };
  const mapData = props.data || cachedData.current?.data;
  const useTotal = props.total || cachedData.current?.total;
  const pages = React.useMemo(() => {
    const total = useTotal || 0;
    if (total < 10) return 1;
    return Math.ceil(total / 10);
  }, [useTotal]);
  return { data: mapData, pages };
};

export default useCacheQuery;
